import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringMicroclimatePage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Microclima'

  const description =
    'Per la corretta gestione delle sedi e postazioni lavorative e per la valutazione dei rischi da agenti fisici, biologici e chimici ING GROUP offre i seguenti rilievi strumentali:<br> Microclima (D.Lgs. 81/2008)<br><br> ING GROUP dispone di tutte le strumentazioni necessarie all’esecuzione dei rilievi strumentali individuati. Per i rilievi dei livelli di vibrazione disponiamo del CENTRALINA MICROCLIMATICA R Log.<br><br> I rilievi sul MICROCLIMA sono assicurati:<br> Per <b>AMBIENTE DI LAVORO</b> – Rilievo effettuato seguendo una funzione aziendale nell’espletamento delle sue mansioni tipo in un periodo di tempo che sia rappresentativo della giornata lavorativa standard, per rilevare il condizioni microclimatiche disponibili alla specifica funzione aziendale nella giornata lavorativa tipo<br> Per <b>POSTAZIONE DI LAVORO</b> – Rilievo effettuato su una postazione di lavoro aziendale in un periodo di tempo che sia rappresentativo della giornata lavorativa standard, per rilevare le condizioni microclimatiche disponibili nella specifica postazione aziendale nella giornata lavorativa tipo<br><br> La conduzione dei rilievi strumentali rispetta questo schema, per assicurare l’analisi di dettaglio di tutti gli eventuali rischi presenti valutati in funzione dei seguenti parametri:<br> • Tipologia di rischio;<br> • Postazione lavorativa;<br> • Ambiente di lavoro;<br> • Funzione aziendale;<br> • Mansioni di dettaglio.<br><br> In questi anni abbiamo misurato il <b>MICROCLIMA</b> in realtà industriali di produzione e di servizi ed uffici oltre che in aziende di logistica ed in aeroporti.'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
